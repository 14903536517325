@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Spartan:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@600&family=Open+Sans:wght@800&family=Spartan:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap);
* {
    font-family: 'Spartan', sans-serif;
    font-weight: 900 !important;
}

@-webkit-keyframes toColor {
    0%    { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
    25%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
    50%   { -webkit-filter: grayscale(0%); filter: grayscale(0%); }
    75%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
    100%  { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
}

@keyframes toColor {
    0%    { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
    25%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
    50%   { -webkit-filter: grayscale(0%); filter: grayscale(0%); }
    75%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
    100%  { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
}

@-webkit-keyframes myAnim {
    0% {
        opacity: 0;
      }
    
      100% {
        opacity: 1;
      }
  }

@keyframes myAnim {
    0% {
        opacity: 0;
      }
    
      100% {
        opacity: 1;
      }
  }

.fades {
    -webkit-animation: myAnim 1s ease 0s 1 normal forwards;
            animation: myAnim 1s ease 0s 1 normal forwards;
}

.loadingDiv {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.loadingScreen {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100vw;
    overflow: none;
    -webkit-animation: toColor 3s;
            animation: toColor 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.alertImage {
    border-radius: 20px;
    width: 40%;
}

.svgPlace {
    padding-top: '5px' !important;
}

input {
    border-radius: 10px !important;
    padding-left: 10px;
    margin-left: 40px !important;
}

.submitArrow {
    margin-left: 5px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 40px;
    height: 36px;
}


  .element {
    display: inline-block;
    width: auto;
    height: auto;
    
    
  }


.contentContainer {
    width: auto;
    max-width: 1920px;
    margin: 0 auto;
}

.container2 {
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 80%;
    height: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.navBarContainer {
    width: auto !important; 
    
}

h1 {
    font-size: 96px !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900 !important;
}

.nav-link {
    font-size: 25px;
}

.navbar navbar-expand navbar-light bg-light {
    background-color: white;
}


.container {
    margin-top: 40px;
}


.subheader {
    font-family: 'Abhaya Libre', serif !important;
    font-size: 32px;
    margin: 0 auto;
}

.footer {
    font-family: 'Abhaya Libre', serif !important;
    font-size: 25px;
    margin: 0 auto;
}

.text {
    font-family: 'IM Fell Great Primer', serif;
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: black;
  }


img {
    width: 80%;
}

.dayOfWeek {
    font-size: 40px !important;

}

time {
    font-size: 70px;
    text-transform: uppercase;
    margin: 0px;
    padding: 0px;
}

.atl {
    font-size: 40px !important;
    margin: 0px !important;
    padding: 0px !important;
    
}

.btn-primary {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    border-width: thin !important;
}

iframe {
    width: 90%;
    height: 600px; /*252.5*/
}

.streaming {
    width: 90%;
    height: 390px;
}

.pageContainer {
    width: auto;
    max-width: 1000px;
    margin: 0 auto;
}

.row {
    margin: 0 auto !important;
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    -webkit-flex: 33% 1;
            flex: 33% 1;
    max-width: 33%;
    padding: 0 4px;
  }

  /* Create four equal columns that sits next to each other */
  .column3 {
    -webkit-flex: 33% 1;
            flex: 33% 1;
    max-width: 33%;
    padding: 0 4px;
    width: 100% !important;
  }

  .column2 {
    -webkit-flex: 50% 1;
            flex: 50% 1;
    max-width: 50%;
    padding: 0 4px;
    width: 100% !important;
  }

  .column1 {
    -webkit-flex: 100% 1;
            flex: 100% 1;
    max-width: 100%;
    padding: 0 4px;
    width: 100% !important;
  }

  .slick-slider {
      width: auto;
      margin: 0 auto;
  }
  
  .bookSlide img {
      width: auto;
      height: 600px;
      margin: 0 auto;
  }

  .current {
      font-size: 30px !important;
      margin: 0 auto;
      margin-bottom: 6px;
  }

  .bookDescription {
      font-size: 20px;
  }
  

@media only screen and (min-width: 0px) and (max-width: 800px)
{

    .loadingDiv {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        
    }
    
    .loadingScreen {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100vw;
        overflow: none;
    }

    .alertImage {
        border-radius: 10px;
        width: 90%;
    }

    .svg {
        padding-top: '0px';
    }

    .submitArrow {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        width: 25px;
        height: auto;
    }

    input {
        border-radius: 8px !important;
        padding-left: 10px;
        margin-left: 25px !important;
    }

    textarea {
        padding-left: 10px;
    }

    html {
        width: 100%;
    }

    h1 {
        font-size: 45px !important;
    }

    .current {
        font-size: 20px !important;
    }

    .bookDescription {
        font-size: 15px;
    }

    .contentContainer {
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%;
        height: 100%;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .pageContainer {
        width: 95%;
    }

    .container {
        margin-top: 0;
        width: inherit !important;
        overflow: hidden !important;
    }
    
    .nav-link {
        padding: 0px !important;
        content: "\a";
        font-size: 18px;
        text-align: center;
        line-height: 1 !important;
    }

    .navbar-nav {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
    }

    img {
        width: 90%;
    }

    .subheader {
        font-size: 18px;
    }

    .footer {
        font-size: 15px;
    }

    .text {
        font-size: 15px;
      }

    .dayOfWeek {
        font-size: 20px !important;
    
    }
    
    time {
        font-size: 38px;
        text-transform: uppercase;
        margin: 0px;
        padding: 0px;
    }
    
    .atl {
        font-size: 20px !important;
        margin: 0px !important;
        padding: 0px !important;
        
    }

    iframe {
        width: 90%;
        height: 250px; /*252.5*/
    }

    .slick-slider {
        width: 80%;
        margin: 0 auto;
    }
    
    .bookSlide img {
        width: auto;
        height: 400px;
        margin: 0 auto;
    }

}



